<template>
  <div>
    <div class="spirit w1400">
      <TitleMain
        :textMain="language.mainTitle"
        :textSub="language.subTitle"
        :leftDecor="false"
        :rightDecor="true"
        class="main_title txt-left"
      />
      <!-- 主區塊 -->
      <div class="content_wrapper">
        <div class="bg">
          <img class="bg-img" src="@/assets/imgs/about/spirit_bg.png" alt="">
        </div>
        <!-- 左圖 -->
        <div class="col50">
          <img src="@/assets/imgs/about/img_1.png" alt="">
        </div>

        <!-- 右內容 -->
        <div class="col50">
          <!-- 標題 -->
          <div class="title_wrapper">
            <template v-for="(title, tk) in language.contentTitle">
              <span
                class="h4 txt-main title"
                :key="`title${tk}`">{{title}}</span>
              <Deco
                :key="`title_deco${tk}`"
                class="deco"
                v-if="tk !== language.contentTitle.length -1"/>
            </template>
          </div>
          <!-- 內文 -->
          <div class="content">{{language.content}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleMain from '@/components/TitleMain.vue';
import Deco from '@/components/icon/about/Deco.vue';
import languageMixin from '@/mixins/languageMixin';
import { spirit, spiritEN } from '@/lib/const';

export default {
  name: 'Spirit',
  mixins: [languageMixin],
  components: {
    TitleMain,
    Deco,
  },
  data() {
    return {
      spiritLang: '',
      spirit,
      spiritEN,
    };
  },
  computed: {
    language() {
      if (this.lang === 'TW') {
        return spirit;
      }
      return spiritEN;
    },
  },
  watch: {
  },
};
</script>

<style lang="scss" scoped>
.spirit {
  position: relative;
  padding: 5% 0;

  .main_title {
    // display: block;
    margin-bottom: 5%;
  }

  .content_wrapper {
    position: relative;

    .bg {
      position: absolute;
      clip-path: polygon(0% 0%, 94% 0%, 100% 8%, 100% 100%, 0% 100%);
      width: 60%;
      height: 90%;
      right: 0;
      bottom: -4%;
      background-color: #fff;

      .bg-img {
        position: absolute;
        max-width: 341px;
        width: 100%;
        height: auto;
        right: 0;
        bottom: 0;
      }
    }

    .col50  {
      position: relative;
      height: 100%;
    }

    .title_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 6.5%;
      padding-right: 24px;
      padding-left: 24px;
      .deco {
        margin: 0 10px;
      }
    }

    .content {
      padding: 14%;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 30px;
    &.w1400 {
      width: 100%;
    }
    .main_title {
      // width: 90%;
      margin: 0 auto 5% 5%;
    }
    .content_wrapper {
      &>.col50, .bg {
        width: 100%;
        clip-path: none;

        &>img {
          display: block;
          width: 80%;
        }

        .content {
          padding: 6%;
        }
      }
    }
  }
}
</style>
