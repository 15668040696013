<template>
  <div class="features">
    <TitleMain
      :textMain="language.mainTitle"
      :textSub="language.subTitle"
      :leftDecor="true"
      :rightDecor="true"
      class="main_title txt-center"
    />
    <!-- 中間主要區塊 -->
    <div class="main">
      <div
        ref="parallax_bg"
        class="feature_bg"
        :style="{ backgroundImage: `url('${bgPic}')` }"
      ></div>
      <!-- <img class="bg" src="@/assets/imgs/about/img_2.png" alt=""> -->
      <div class="w1400">
        <div class="main_content">
          <div class="title_wrapper">
            <Deco fill="#fff"/>
            <span class="h4">{{language.contentTitle}}</span>
            <Deco fill="#fff"/>
          </div>
          <p>{{language.content}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bgPic from '@/assets/imgs/about/img_2.png';
import TitleMain from '@/components/TitleMain.vue';
import Deco from '@/components/icon/about/Deco.vue';
import { features, featuresEN } from '@/lib/const';
import { parallaxPosition } from '@/lib/public';
import languageMixin from '@/mixins/languageMixin';

export default {
  name: 'Features',
  mixins: [languageMixin],
  components: {
    TitleMain,
    Deco,
  },
  data() {
    return {
      bgPic,
      featuresLang: '',
      features,
      featuresEN,
    };
  },
  methods: {
    scrollHandler() {
      const target = this.$refs.parallax_bg;
      target.style.backgroundPosition = parallaxPosition(target);
    },
  },
  computed: {
    language() {
      if (this.lang === 'TW') {
        return features;
      }
      return featuresEN;
    },
  },
  created() {
    window.addEventListener('scroll', this.scrollHandler);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  watch: {
  },
};
</script>

<style lang="scss" scoped>
.features {
  background-color: #fff;

  .main_title {
    padding: 3% 0;
    width: 100%;
    background-color: #f6f6f6;
  }

  .main {
    .feature_bg {
      width: 100%;
      padding-bottom: calc(550 / 1920) * 100%;
      background-position: top center;
      background-size: 150%;
      background-repeat: no-repeat;
      background-attachment: scroll;
    }

    .main_content {
      margin-top: -19%;
      margin-right: auto;
      padding: 6.1%;
      width: 50%;
      background-color: #47040F;
      color: #fff;
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 10% 100%, 0% 90%);

      .title_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 3%;

        .h4 {
          padding: 0 15px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .main_title {
      padding: 30px 0 5%;
    }

    .main {
      overflow: hidden;
      .feature_bg {
        transform: scale(1.5) translate(-14%, 17%);
      }
      .main_content {
        margin-top: 0%;
        padding-top: 12%;
        padding-bottom: 12%;
        width: 100%;
      }
    }
  }
}
</style>
