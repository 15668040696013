<template>
  <div class="prospect">
    <img class="bg" src="@/assets/imgs/about/prospects_bg.png" alt="">
    <div class="container w1400">
      <TitleMain
        :textMain="language.mainTitle"
        :textSub="language.subTitle"
        :leftDecor="false"
        :rightDecor="true"
        class="main_title"
      />

      <div
        class="content"
        :class="{ 'reverse' : k === 1 }"
        v-for="(content, k) in language.content" :key="`c_${k}`">
        <div class="col50 card">
          <h4 class="card_title txt-main">{{content.title}}
            <Deco class="card_title_deco"/>
          </h4>
          <div class="hr_deco"></div>
          <p class="card_content txt-black">{{content.description}}</p>
        </div>
        <div class="col50 card_img_bg"
        :style="`background-image: url(${require(`@/assets/imgs/about/${content.imageName}`)})`">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleMain from '@/components/TitleMain.vue';
import Deco from '@/components/icon/about/Deco.vue';
import { prospect, prospectEN } from '@/lib/const';
import languageMixin from '@/mixins/languageMixin';

export default {
  name: 'Prospect',
  mixins: [languageMixin],
  data() {
    return {
      prospectLang: '',
      prospect,
      prospectEN,
    };
  },
  components: {
    TitleMain,
    Deco,
  },
  methods: {
  },

  watch: {
    $route: {
      handler(val) {
        const { lang } = val.query;
        if (lang === 'EN') {
          this.initLang();
        } else {
          this.initLang();
        }
      },
    },
  },
  computed: {
    language() {
      if (this.lang === 'TW') {
        return prospect;
      }
      return prospectEN;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/helper/_variable.scss';
.prospect {
  position: relative;
  background-color: $color-main;

  .bg {
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 768px;
    width: 100%;
  }

  .container {
    padding: 5% 0;
  }

  .main_title.title-main {
    padding-bottom:  5%;
    .txt-main {
      color: #fff;
    }
    .txt-black {
      color: #fff;
    }
  }
  .content {
    display: flex;
    width: 100%;
    min-height: 350px;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }

    &.reverse {
      flex-direction: row-reverse;
    }

    .card {
      clip-path: polygon(0% 10%, 10% 0%, 100% 0%, 100% 100%, 0% 100%);
      background-color: #fff;
    }

    .card_img_bg {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .card_title {
      padding: 10% 0 5% 13%;

      .card_title_deco {
        margin-left: 10px;
        margin-bottom: 3px;
      }
    }

    .card_content {
      padding: 5% 13%;
    }

    .hr_deco {
      width: 20%;
      background-color: $color-red;
      height: 1px;
    }

    &:last-child {
      margin-top: 5%;
      .card {
        clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 90% 100%, 0% 100%);
      }
      .card_title {
        padding-top: 5%
      }
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      padding-top: 30px;
      padding-bottom: 10%;
    }
    .content {

      &, &.reverse {
        flex-direction: column-reverse;
      }

      .col50 {
        width: 100%;
      }

      .card_img_bg {
        max-height: initial;
        padding-bottom: 51%;
      }

      .card, &:last-child .card {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 10% 100%, 0% 90%);
      }

      .card_content {
        padding-bottom: 12%;
      }

      &:last-child {
        margin-top: 12%;
      }
    }
  }
}
</style>
