<template>
  <div class="process">
    <div class="title_wrapper">
      <Deco/>
      <span class="h4 txt-main">{{language.contentTitle}}</span>
      <Deco/>
    </div>
    <div class="description txt-black txt-center">
      <p>{{language.description}}</p>
    </div>
    <div class="content_wrapper">
      <div class="content w1400" :class="{ 'content_mo' : innerWidth <= 768}">
        <img class="step_img" v-if="innerWidth > 768" :src="pic" alt="">
        <img class="step_img" v-else :src="picMobile" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import Deco from '@/components/icon/about/Deco.vue';
import { process, processEN } from '@/lib/const';
import languageMixin from '@/mixins/languageMixin';
import stepsPicEN from '@/assets/imgs/about/step_en.png';
import stepsPic from '@/assets/imgs/about/step.png';
import stepsPicMo from '@/assets/imgs/about/step_m.png';
import stepsPicENmo from '@/assets/imgs/about/step_m_en.png';

export default {
  name: 'Process',
  mixins: [languageMixin],
  components: {
    Deco,
  },
  data() {
    return {
      pageLang: '',

      stepsPicENmo,
      stepsPicEN,
      stepsPic,
      stepsPicMo,

      processLang: '',
      process,
      processEN,
      innerWidth: 1920,

      // currentPic: null,
      // currentPicMo: null,
    };
  },
  computed: {
    language() {
      if (this.lang === 'TW') {
        return process;
      }
      return processEN;
    },
    pic() {
      if (this.lang === 'TW') {
        return stepsPic;
      }
      return stepsPicEN;
    },
    picMobile() {
      if (this.lang === 'TW') {
        return stepsPicMo;
      }
      return stepsPicENmo;
    },
  },
  methods: {
    setCurrWidth() {
      this.innerWidth = window.innerWidth;
    },
  },
  created() {
    window.addEventListener('resize', this.setCurrWidth);
    this.setCurrWidth();
  },
};
</script>

<style lang="scss" scoped>
.process {
  padding-top: 5%;
  background-color: #fff;
  .title_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 3%;
    padding-right: 24px;
    padding-left: 24px;

    .h4 {
      padding: 0 15px;
    }
  }

  .description {
    margin: 0 auto;
    padding: 0 5% 3%;
    max-width: 640px;
  }

  .content_wrapper {
    background-color: #f6f6f6;

    .content_mo {
      padding: 0 5%;
    }

    .step_img {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 12%;
    .description {
      padding-bottom: 8%;
    }
  }
}
</style>
