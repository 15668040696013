<template>
  <div class="about-product">
    <img src="@/assets/imgs/about/cta_bg.svg" class="bg" alt="">
    <div class="content_wrapper">
      <div class="col50">
        <!-- <BgProducts class="bg"/> -->
        <div class="description_wrapper">
          <div class="deco_top">
            <Deco />
            <div class="hr_deco"></div>
          </div>
          <h4 class="txt-main description">
            <span style="display: block" v-for="t of language" :key="t">{{t}} </span>
          </h4>
          <div class="deco_bottom">
            <div class="hr_deco"></div>
            <Deco />
          </div>
          <div class="btn_wrapper">
            <BtnMain
              class="description_btn"
              :text="'OUR PRODUCTS'"
              :btnType="1"
              @click.native="clickHandler()"
            />
          </div>
        </div>
      </div>
      <div class="col50 swiper_wrapper"
          @mouseenter="$refs.productSwiper.swiper.autoplay.stop()"
          @mouseleave="$refs.productSwiper.swiper.autoplay.start()">
        <swiper v-if="serieses.length" ref="productSwiper" class="swiper" :options="swiperOption">
          <swiper-slide
            v-for="(item, k) in serieses" :key="`slide_${k}`"
            class="swiper_container"
            @click.native="clickHandler(item.name)"
          >
            <img :src="require(`@/assets/imgs/about/cta_${k+1}.png`)" alt="">
            <div
              class="series_bg_img"
              :style="{ backgroundImage: `url('${item.pic}')` }"
            ></div>
            <div class="series_cover"></div>
            <div class="swiper_content">
              <p class="large">{{item.name}}</p>
              <h5>{{item.name_en}}</h5>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import Deco from '@/components/icon/about/Deco.vue';
import BtnMain from '@/components/BtnMain.vue';
// import { serieses } from '@/lib/const';
import goToProductMixin from '@/mixins/goToProductMixin';
import languageMixin from '@/mixins/languageMixin';
import requestApi from '@/lib/http';
import { formatSerieses } from '@/utils/formatResponse';

export default {
  name: 'Products',
  mixins: [goToProductMixin, languageMixin],
  components: {
    Deco,
    BtnMain,
  },
  data() {
    return {
      serieses: [],
      swiperOption: {
        autoplay: true,
        delay: 0,
        pauseOnMouseEnter: true,
        loop: false,
        mousewheel: true,
        slidesPerView: 3,
        spaceBetween: 0,
        pagination: false,
        breakpoints: {
          680: {
            slidesPerView: 2,
          },
          540: {
            slidesPerView: 1.5,
          },
        },
      },
    };
  },
  computed: {
    language() {
      const txtTW = ['製造高品質與價格合理的廚刀，', '是我們堅持的原則，', '也是六協興業經營自有品牌的理想與目標！'];
      const txtEN = ['With our vision and ultimate goal of becoming a well-established brand in the market, we insist to provide our customers the high quality knives with reasonable price.'];
      if (this.lang === 'TW') {
        return txtTW;
      }
      return txtEN;
    },
  },
  methods: {
    clickHandler(lv1) {
      let routeData = { name: 'Products' };

      const lv1Init = lv1 || this.menuListFromVuex?.[0]?.name;

      const lv2 = this.menuListFromVuex
        ?.find((main) => main.name === lv1Init)?.list?.[0]?.name;

      routeData = this.getProductRouteDate(routeData, lv1Init, lv2);

      this.$router.push(routeData);
    },
    async getData() {
      const { entries: data } = await requestApi('common.getSerieses', {
        limit: 7, // 首頁排版最多顯示7筆
      });
      this.serieses = data.map(formatSerieses).sort((a, b) => a.sort - b.sort);
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';

.about-product {
  position:  relative;
  padding: 8% 0;
  background-color: #fff;
  overflow: hidden;

  .bg {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .content_wrapper {
    display: flex;
    width: 100%;

    &>.col50{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .description_wrapper {
    padding: 6%;

    .hr_deco {
      margin: 0 15px;
      background-color: $color-red;
      width: 20%;
      height: 1px;
    }

    .deco_top, .deco_bottom {
      display: flex;
      align-items: center;
    }

    .deco_bottom{
      width: 100%;
      margin-left: auto;

      .hr_deco {
        margin-left: auto;
      }
    }

    .description {
      padding: 5% 0;
    }

    .description_btn {
      margin-top: 3%;
    }
  }

  .swiper_container {
    position: relative;
    cursor: pointer;
    .series_bg_img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: center/cover no-repeat;
    }
    .series_cover {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, .3);
      transition: opacity .1s linear;
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        .series_cover {
          opacity: 1;
        }
      }
    }

    .swiper_content {
      position: absolute;
      padding: 0 2%;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      color: #fff;
      text-align: center;
    }
  }

  @media screen and (max-width: 1024px) {
    padding-top: 10%;

    .bg {
      transform: scale(3) translateX(32%);
    }

    .content_wrapper {
      flex-direction: column-reverse;

      .col50 {
        width: 100%;

        &.swiper_wrapper {
          width: 80%;
          margin: 0 auto;
        }
      }
    }
    .btn_wrapper {
      display: flex;
      margin-top: 10%;
      justify-content: center;
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 60px;
    .description_wrapper {
      padding-bottom: 0;
      .description {
        line-height: 2rem;
      }
    }
    .content_wrapper {
      .col50 {
        &.swiper_wrapper {
          width: 90%;
          margin: 0 auto;
        }
      }
    }
  }

}
</style>
